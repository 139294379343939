import * as React from 'react';
import { graphql } from 'gatsby';
import { rem } from 'polished';
import { styled } from '../gatsby-theme-stitches/stitches.config';
import JobInterviewMemberHandle from './JobInterviewMemberHandle';

export const query = graphql`
  fragment JobInterviewBodyInterviewQuestion_doc on Strapi_ComponentTechstoryInterviewQuestion {
    question
    interviewQuestionAnwser {
      answer
      memberProfile {
        ...JobInterviewMemberHandle_profile
      }
    }
  }
`;

type JobInterviewBodyInterviewQuestionProps = {
  // FIXME type
  doc: any,
};

const Container = styled('section', {
  marginY: rem(50),
});

const Question = styled('h2', {
  color: 'text',
  fontWeight: 'bold',
  textAlign: 'center',
  textDecoration: 'none',
  '::before': {
    content: '"Q. "',
    color: 'primary',
  },
});

const AnswerContainer = styled('div', {
  marginY: rem(64),
});

const AnswerContent = styled('blockquote', {
  marginX: 0,
  whiteSpace: 'pre-line',
  wordBreak: 'break-all',
});

const JobInterviewBodyInterviewQuestion: React.FC<JobInterviewBodyInterviewQuestionProps> = ({ doc }) => {
  return (
    <Container>
      {doc.question ? <Question>{doc.question}</Question> : null}
      {doc.interviewQuestionAnwser.map(
        (item, i) => item.memberProfile || item.answer
          ? (
            <AnswerContainer key={i}>
              {item.memberProfile && <JobInterviewMemberHandle profile={item.memberProfile} />}
              {item.answer && <AnswerContent dangerouslySetInnerHTML={{ __html: item.answer }} />}
            </AnswerContainer>
          )
          : null
      )}
    </Container>
  );
};

export default JobInterviewBodyInterviewQuestion;
