import * as React from 'react';
import { graphql } from 'gatsby';

import { styled } from '../gatsby-theme-stitches/stitches.config';

export const query = graphql`
  fragment JobInterviewBodyNote_doc on Strapi_ComponentTechstoryNote {
   content
  }
`;

type JobInterviewBodyNoteProps = {
  // FIXME type
  doc: any,
};

const Container = styled('div', {
  whiteSpace: 'pre-line',
  wordBreak: 'break-all',
  '& li > p': {
    margin: 0,
  },
});

const JobInterviewBodyNote: React.FC<JobInterviewBodyNoteProps> = ({ doc }) => {
  return (
    <Container dangerouslySetInnerHTML={{ __html: doc.content }} />
  );
};

export default JobInterviewBodyNote;
