import * as React from 'react';
import { graphql } from 'gatsby';
import { rem } from 'polished';
import { styled } from '../gatsby-theme-stitches/stitches.config';

export const query = graphql`
  fragment JobInterviewBodyReferences_doc on Strapi_ComponentTechstoryReferences {
    referencesTitle
    referenceLink {
      name
      link
    }
  }
`;

type JobInterviewBodyReferencesProps = {
  // FIXME type
  doc: any,
};

const Container = styled('section', {
  marginY: rem(64),
});

const ReferenceTitle = styled('h2', {
  fontSize: '1.2rem',
  fontWeight: 'bold',
  color: 'text',
});

const ReferenceList = styled('ul', {
});

const ReferenceListItem = styled('li', {
});

const ReferenceLink = styled('a', {
  color: 'primary',
});

const JobInterviewBodyReferences: React.FC<JobInterviewBodyReferencesProps> = ({
  doc,
}) => {
  if (!(doc.referenceLink && doc.referenceLink.length > 0)) {
    return null;
  }

  return (
    <Container>
      {doc.referencesTitle && (
        <ReferenceTitle>{doc.referencesTitle}</ReferenceTitle>
      )}
      <ul>
        {doc.referenceLink.map(item => (
          <li key={item.link}>
            <ReferenceLink href={item.link} target="_blank" rel="noopener">
              {item.name}
            </ReferenceLink>
          </li>
        ))}
      </ul>
    </Container>
  );
};

export default JobInterviewBodyReferences;
