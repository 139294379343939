import type { PageProps } from 'gatsby';

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { graphql, Link } from 'gatsby';
import { rem } from 'polished';
import { motion } from 'framer-motion';
import Img from 'gatsby-image';
import { styled } from '../gatsby-theme-stitches/stitches.config';
import { layoutTransitionVariants } from '../utils/transition';
import JobInterviewBodyInterviewQuestion from '../components/JobInterviewBodyInterviewQuestion';
import JobInterviewBodyKeyText from '../components/JobInterviewBodyKeyText';
import JobInterviewBodyNote from '../components/JobInterviewBodyNote';
import JobInterviewBodyReferences from '../components/JobInterviewBodyReferences';
import JobInterviewBodyImage from '../components/JobInterviewBodyImage';

export const query = graphql`
  query StoryPage($id: ID!) {
    strapi {
      techstoryPeopleStory(id: $id) {
        urlSlug
        title
        description
        intro
        thumbnail {
          alternativeText
          url
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1200) {
                src
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        body {
          __typename
          ...JobInterviewBodyImage_doc
          ...JobInterviewBodyNote_doc
          ...JobInterviewBodyReferences_doc
          ...JobInterviewBodyKeyText_doc
          ...JobInterviewBodyInterviewQuestion_doc
        }
      }
    }
  }
`;

// FIXME type
type StoryPageProps = PageProps<any>;

const Container = styled('article', {
  color: 'text',
});

const StoryHeader = styled(motion.header, {
  position: 'relative',
  width: '100%',
  height: rem(500),
  maxHeight: '80vw',
});

const Thumbnail = styled(Img, {
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '100%',
});

const ThumbnailPlaceholder = styled('div', {
  top: 0,
  width: '100%',
  height: '100%',
});

const ThumbnailDim = styled(motion.div, {
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '100%',
  backgroundColor: '#000',
});

const TitleContainer = styled('div', {
  $contentWrapper: true,
  transform: 'translateY(-100%)',
});

const StoryTitle = styled(motion.h1, {
  display: 'inline-block',
  color: '#fff',
  variants: {
    size: {
      default: {
        fontSize: '2rem',
      },
      large: {
        fontSize: '3rem',
      },
    },
  },
});

const MainContentBox = styled(motion.main, {
  $contentWrapper: true,
  fontSize: '1.2rem',
  lineHeight: 1.5,
  '& .block-img': {
    textAlign: 'center',
    '> img': {
      width: '100%',
    },
  },
  '& a': {
    color: 'primary',
  },
  variants: {
    wide: {
      true: {
        lineHeight: 1.8,
      },
      false: {
      },
    },
  },
});

const Intro = styled('div', {
  fontSize: '1.1rem',
  marginY: rem(100),
  whiteSpace: 'pre-line',
});

const StoryFooter = styled('div', {
  $contentWrapper: true,
  paddingY: rem(64),
});

const Divider = styled('hr', {
  borderTop: '1px solid primary',
  marginTop: rem(96),
});

const HomeLink = styled(Link, {
  fontSize: '1.5rem',
  float: 'right',
  color: 'primary',
  marginTop: '1rem',
});

const StoryPage: React.FC<StoryPageProps> = ({
  data,
}) => {
  const story = data.strapi.techstoryPeopleStory;
  const thumbnailImageFluid = story?.thumbnail?.imageFile?.childImageSharp.fluid ?? null;

  const title = story.title;
  const description = story.description;
  return (
    <Container>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        {thumbnailImageFluid ? (
          <meta property="og:image" content={thumbnailImageFluid.src} />
        ) : null}
      </Helmet>
      <StoryHeader layoutId={`story-thumbnail-${story.urlSlug}`}>
        {thumbnailImageFluid ? (
          <Thumbnail
            fadeIn={false}
            fluid={thumbnailImageFluid}
          />
        ) : (
          <ThumbnailPlaceholder />
        )}
        <ThumbnailDim
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.5 }}
        />
        <TitleContainer>
          <StoryTitle
            layoutId={`story-title-${story.urlSlug}`}
            size={{ initial: 'default', lg: 'large' }}
          >
            {title}
          </StoryTitle>
        </TitleContainer>
      </StoryHeader>
      <MainContentBox
        variants={layoutTransitionVariants}
        animate="enter"
        initial="initial"
        exit="exit"
        wide={{ initial: false, md: true }}
      >
        <Intro dangerouslySetInnerHTML={{ __html: story.intro }} />
        {story.body.map((doc, i) => {
          switch (doc.__typename) {
            case 'Strapi_ComponentTechstoryInterviewQuestion': return (
              <JobInterviewBodyInterviewQuestion key={i} doc={doc} />
            );
            case 'Strapi_ComponentTechstoryKeyText': return (
              <JobInterviewBodyKeyText key={i} doc={doc} />
            );
            case 'Strapi_ComponentTechstoryNote': return (
              <JobInterviewBodyNote key={i} doc={doc} />
            );
            case 'Strapi_ComponentTechstoryImage': return (
              <JobInterviewBodyImage key={i} doc={doc} />
            );
            case 'Strapi_ComponentTechstoryReferences': return (
              <JobInterviewBodyReferences key={i} doc={doc} />
            );
          }
        })}
      </MainContentBox>
      <StoryFooter>
        <Divider />
        <HomeLink to="/">다른 스토리 보러가기 →</HomeLink>
      </StoryFooter>
    </Container>
  );
};

export default StoryPage;
