import * as React from 'react';
import { graphql } from 'gatsby';
import { em } from 'polished';
import { styled } from '../gatsby-theme-stitches/stitches.config';

export const query = graphql`
  fragment JobInterviewBodyKeyText_doc on Strapi_ComponentTechstoryKeyText {
    content
  }
`;

type JobInterviewBodyKeyTextProps = {
  // FIXME type
  doc: any,
};

const Container = styled('blockquote', {
  position: 'relative',
  textAlign: 'center',
  width: em(300),
  maxWidth: '80%',
  margin: '10rem auto',
  '::before, ::after': {
    position: 'absolute',
    fontSize: '5em',
    width: '3rem',
    height: '3rem',
    lineHeight: 1,
    color: 'primary',
  },
  '::before': {
    content: '"“"',
    bottom: '100%',
    transform: 'translate(-50%, -50%)',
  },
  '::after': {
    content: '"”"',
    top: '100%',
    transform: 'translate(-50%, 0)',
  },
  variants: {
    size: {
      default: {
        fontSize: '1.5rem',
      },
      large: {
        fontSize: '2rem',
        '::before': {
          transform: 'translate(-100%, -100%)',
        },
        '::after': {
          transform: 'translate(-100%, 0)',
        },
      },
    },
  },
});

const JobInterviewBodyKeyText: React.FC<JobInterviewBodyKeyTextProps> = ({ doc }) => {
  if (!doc.content) {
    return null;
  }

  return (
    <Container
      size={{ initial: 'default', lg: 'large' }}
    >
      <p>{doc.content}</p>
    </Container>
  );
};

export default JobInterviewBodyKeyText;
