import * as React from 'react';
import { graphql } from 'gatsby';
import _Img from 'gatsby-image';
import { rem } from 'polished';
import { styled } from '../gatsby-theme-stitches/stitches.config';

export const query = graphql`
  fragment JobInterviewBodyImage_doc on Strapi_ComponentTechstoryImage {
    altText
    image {
      url
      imageFile {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;

type JobInterviewBodyImageProps = {
  // FIXME type
  doc: any,
};

const Container = styled('figure', {
  margin: 0,
});

const Img = styled(_Img, {
});

const Caption = styled('figcaption', {
  marginY: rem(8),
  textAlign: 'center',
});

const JobInterviewBodyImage: React.FC<JobInterviewBodyImageProps> = ({ doc }) => {
  if (!doc.image) {
    return null;
  }

  const imageFluid = doc.image?.imageFile?.childImageSharp.fluid ?? null;
  if (!imageFluid) {
    return null;
  }

  return (
    <Container>
      <Img fluid={imageFluid} alt={doc.altText} />
      <Caption>{doc.altText}</Caption>
    </Container>
  );
};

export default JobInterviewBodyImage;
