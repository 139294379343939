import * as React from 'react';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import { styled } from '../gatsby-theme-stitches/stitches.config';

export const query = graphql`
  fragment JobInterviewMemberHandle_profile on Strapi_TechstoryMemberProfile {
    id
    called
    thumbnail {
      url
      imageFile {
        childImageSharp {
          fixed(width: 300, height: 300) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  }
`;

type JobInterviewMemberHandleProps = {
  // FIXME type
  profile: any,
};

const Container = styled('figure', {
  textAlign: 'center',
});

const Handle = styled('figcaption', {
  fontWeight: 'bold',
});

const AvatarImage = styled(Img, {
  borderRadius: '100%',
  variants: {
    size: {
      small: {
        width: '100px !important',
        height: '100px !important',
      },
      large: {
        width: '125px !important',
        height: '125px !important',
      },
    },
  },
});

const JobInterviewMemberHandle: React.FC<JobInterviewMemberHandleProps> = ({ profile }) => {
  const thumbnailFixed = profile?.thumbnail?.imageFile?.childImageSharp.fixed;

  return (
    <Container>
      <AvatarImage fixed={thumbnailFixed} size={{ initial: 'small', lg: 'large' }} />
      <Handle>{profile.called}</Handle>
    </Container>
  );
};

export default JobInterviewMemberHandle;
